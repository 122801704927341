/* eslint-disable */
import $ from 'jquery';
import I18n from 'i18n/i18n'
import toastr from "utils/toast";

require('magnific-popup');


export function initTooltips() {
  $(document).on('mouseover', '.actions-tooltip button, .actions-tooltip a', function () {
    $('.actions-tooltip button, .actions-tooltip a').tooltip({
      tooltipClass: "mytooltip",
      position: {
        my: "center bottom-10",
        at: "center top",
        using: function (position, feedback) {
          $(this).css(position);
          $("<div>")
            .addClass("arrow")
            .addClass(feedback.vertical)
            .addClass(feedback.horizontal)
            .appendTo(this);
        }
      }
    });
  })
};

export function podShippingCourier() {
  $(document).on('click', '#button-shipping-courier', function onClick() {
    var getParams = $(this).data();

    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        var textField = document.createElement('textarea')
        textField.innerText = getParams.code
        document.body.appendChild(textField)
        textField.select()
        Promise.resolve(
          document.execCommand('copy')
        ).then(() => {
          window.open(getParams.url, '_blank')
          var title = 'Tracking Code Copied'
          var options = {
            body: 'Paste the code and search to track your Document Return.',
            icon: getParams.icon,
            vibrate: [200, 100, 200]
          }
          var notification = new Notification(title, options)
        })
      } else {
        alert("Notifications blocked. Please enable them in your browser.");
      }
    } else {
      alert("This browser does not support desktop notification");
    }
  })
};

export function showGalleryCustom() {
  $(".basic-popup-gallery a").each(function (index) {
    var extension = $(this).attr('href').split("?")[0].split('.').pop()
    if (extension === 'pdf') {
      $(this).addClass("View_Target")
      $(this).attr("target", "_blank");
    }
  });

  if(!$('.basic-popup-gallery').length) return
  $('.basic-popup-gallery').magnificPopup({
    delegate: 'a:not(.View_Target)',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
    },
    callbacks: {
      open: function () {
        $('.Modal').css({ 'z-index': 1 });
      },
      close: function () {
        $('.Modal').attr('style', '');
      }
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
    }
  });
}

// use to display selected image (file input)
export function readURL(input, display_element_id) {
  var reader;
  if (input.files && input.files[0]) {
    reader = new FileReader;
    reader.onload = function (e) {
      $(`#${display_element_id}`).attr('src', e.target.result);
    };
    reader.readAsDataURL(input.files[0]);
  } else {
    $(`#${display_element_id}`).attr('src', defaultURL);
  }
};

// show password fields
export function initToggleShowPasswordFor(toggleElementID) {
  const isCPS = 'edit-customer-password-state' === toggleElementID;
  var passwordInputID;
  passwordInputID = $(`#${toggleElementID}`).attr("password-field-id")
  if ($(`#${toggleElementID}`).is(':checked')) {
    if (isCPS) {
      $(`#${toggleElementID}`).next().find(".Icon").addClass("green")
    } else {
      $(`#${toggleElementID}`).next(".Icon").addClass("green")
    }
    showPassword(passwordInputID);
  }

  $(document).on("change", `#${toggleElementID}`, function () {
    if ($(this).is(':checked')) {
      if (isCPS) {
        $(this).next().find(".Icon").addClass("green")
      }
      else {
        $(this).next(".Icon").addClass("green")
      }
      showPassword(passwordInputID);
    } else {
      if (isCPS) {
        $(this).next().find(".Icon").removeClass("green")
      }
      else {
        $(this).next(".Icon").removeClass("green")
      }
      hidePassword(passwordInputID);
    }
  });
};

// toogle Pricing LongHaul on booking details page
export function PricingLongHaul() {
  $(document).on("click", ".Pricing-LongHaul-Button", function () {
    $(this).find('i').toggleClass('Collapse-Icon Rotate');
    $('.Pricing-LongHaul-To').toggleClass('Collapse');
    $(this).closest('.Pricing-LongHaul-Group').find('.w100').toggleClass('Pricing-LongHaul-Hide Pricing-LongHaul-Show')
    setTimeout(function () {
      if ($(".Pricing-LongHaul-Button").closest('.Modal').length != 0) {
        checkHeightPopup($(".Pricing-LongHaul-Button").closest('.Modal').find('.Popup'))
      }
    }, 0)
  });
}

// INSIDE METHODS
function updateStateOfSubmitButton(value) {
  if (value) {
    $("#mtcSubmitButton").css("display", "inline-block");
  }
  else {
    $("#mtcSubmitButton").css("display", "none");
  }
};

function showPassword(strID) {
  var p;
  if (strID === void 0) {
    strID = "customer_password";
  }
  p = document.getElementById(strID);
  p.setAttribute('type', 'text');
};

function hidePassword(strID) {
  var p;
  if (strID === void 0) {
    strID = "customer_password";
  }
  p = document.getElementById(strID);
  p.setAttribute('type', 'password');
};


// config basic popup
// Notes:
// Add trigger='open-modal' and modal-id='ID-of-your-modal' to the element you trigger to show the modal
$(function () {
  $(document).on("click", "[trigger='open-modal']", function () {
    var modalID = $(this).attr("modal-id")
    $(`#${modalID}`).addClass("visible");
  });

  $(document).on("click", ".Modal-Head i", function () {
    $(this).parents(".Modal").removeClass("visible");
  });

  $(document).on("click", ".close-basic-modal", function () {
    $(".Modal-Basic").removeClass("visible");
    $('.Modal').removeClass('visible')
  });

  $(document).on('click', '.Modal.no-action', function () {
    // $(this).removeClass('visible');
    closeModal();
  });

  $(document).on('click', '.close-action-modal', function () {
    // $(this).closest('.closure.Modal').removeClass('visible');
    closeModal();
  });

  if ($('.DetailBooking--Photos').length) {
    $('.DetailBooking--Photos').each(function () {
      if ($(this).find('.DetailBooking--ImageViewer').length < 2) {
        $(this).find('.DetailBooking--Photos-List').removeClass('DetailBooking--Photos-List')
      }
    });
  }

  $(document).on('click', '.driver-cancel-handler .driver-cancel-handler-icon', function click() {
    $(this).closest('.driver-cancel-handler').toggleClass('Show')
  })

  $(document).on('click', '.view-popupID', function click(e) {
    if ($(this).hasClass('view-popupID-prevent')) {
      e.preventDefault()
    }

    const getID = $(this).data('id')
    $('#' + getID).addClass('visible')
  })

  $(document).on('click', '.close-popupID', function click() {
    const getID = $(this).data('id')
    $('#' + getID).removeClass('visible')
  })

  $(document).on('click', '.copy-common-action', function copyAction() {
    const textField = document.createElement('textarea')
    textField.innerText = $(this).data('copy')
    document.body.appendChild(textField)
    textField.select()

    Promise.resolve(
      document.execCommand('copy')
    ).then(() => {
      textField.remove()
      toastr.success(I18n.t('webapp.action.copied'))
    })
  })

  $(document).on('click', '.copy-common-action-show-text', function copyAction() {
    const textField = document.createElement('textarea')
    textField.innerText = $(this).data('copy')
    document.body.appendChild(textField)
    textField.select()

    Promise.resolve(
      document.execCommand('copy')
    ).then(() => {
      textField.remove()
      $('.copy-common-show-text').html(I18n.t('webapp.action.copied'))
      $('.copy-common-show-text').css('color', '#fedb00')

      setTimeout(() => {
        $('.copy-common-show-text').html($(this).data('copy'))
        $('.copy-common-show-text').removeAttr('style')
      }, 3000);
    })
  })

  $(document).on('click', '.top-up-js', function () {
    $('.top-up-popup').toggleClass('visible')
  })
})

// Show - hide loading gif photo
export function openLoading() {
  $("#loading-modal.Modal").addClass('visible');
};

export function closeLoading() {
  $("#loading-modal.Modal").removeClass('visible');
};

export function checkHeightPopup(element) {
  if (element.height() + 120 > element.parent().height()) {
    element.addClass('top-align No-Seft');
    $('.close-modal-common').css({
      'min-height': '100%',
      'height': element.height() + 120
    })
  } else {
    element.removeClass('top-align No-Seft');
  }
}

export function closeModal_fix() {
  $(document).on('click', '.close-modal-common', function () {
    $(this).parent().removeClass('visible');
    $(this).parent().find('.Popup').removeAttr();
    $(this).remove();
  });
}

export function closeModal() {
  $('.vertical-scroll.Modal').removeClass('visible');
};
/* eslint-enable */

export function createIframeToSyncPTL(queryString, callback = () => undefined) {
  callback();
  // const ptlDomain = process.env.LTL_CUSTOMER_DOMAIN
  // if (!ptlDomain) {
  //   callback()
  //   return
  // }
  // const ptlDomainPH = process.env.REACT_APP_LTL_CUSTOMER_DOMAIN_PH
  // if (!ptlDomain) {
  //   callback()
  // } else {
  //   const fPTL = document.createElement('iframe')
  //   fPTL.sandbox = 'allow-same-origin allow-scripts allow-popups allow-forms'
  //   fPTL.src = `${ptlDomain}/?${queryString}`
  //   fPTL.onload = function onLoadedIframe() {
  //     fPTL.remove()
  //     callback()
  //   }
  //   document.body.appendChild(fPTL)
  // }

  // if (ptlDomainPH) {
  //   const fPtlPH = document.createElement('iframe')
  //   fPtlPH.sandbox = 'allow-same-origin allow-scripts allow-popups allow-forms'
  //   fPtlPH.src = `${ptlDomainPH}/?${queryString}`
  //   fPtlPH.onload = function onLoadedIframePH() {
  //     fPtlPH.remove()
  //   }
  //   document.body.appendChild(fPtlPH)
  // }
}

export const showLoading = () => {
  const loadingCom = document.getElementById('loading-modal')
  if (loadingCom && !loadingCom.className.includes('visible')) {
    loadingCom.classList.add('visible')
  }
}

export const hideLoading = () => {
  const loadingCom = document.getElementById('loading-modal')
  if (loadingCom && loadingCom.className.includes('visible')) {
    loadingCom.classList.remove('visible')
  }
}
