import { Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import RouteList from 'routers'
import firebaseInit from 'utils/firebaseInit'
import CustomerAPI from 'api/customers'
import * as utilsCommon from 'utils/booking/common'
import * as initApp from 'utils/initApp'
import StorageKeys from 'constants/storage-keys'
import { checkToShowLoadingForNewBooking } from './utils/new_booking/common'
import { Utils } from 'utils/Utils'
import {
  handleListenMessage,
  setAccessToken,
  connectToken
} from './utils/crossStorage'
import * as areaActionCreators from 'store/actions/common/areaActionCreators'
import * as customerActionCreators from 'store/actions/common/customerActionCreators'

import NavBarContainer from './containers/NavBarContainer'
import FreshChat from 'utils/FreshChat'
import { initBasicModal } from 'assets/javascripts/webapp-v2/common/basic-modal'
import LoadingModal from 'components/common/loading_modal/LoadingModal'
import { ThemeProvider } from 'styled-components'
import { SGlobal } from 'SGlobal'
import { theme } from 'components/common/Styled/STheme'
import { COMPANY_APPROVED_MODAL } from 'components/new_booking/guest_flow/constants'
import { accountManageActions } from 'store/toolkit/accountManage/accountManage.reducer'
import accountAction from 'store/actions/common/accountAction'
import { appsFlyerSetCUID, appsFlyerTrackEvent, appsFlyerTurnOnDevMode } from 'utils/trackingAppsFlyer'
import { SIGN_OUT } from 'constants/trackingAppsFlyer'

const currentToken = (isLoggedOut) =>  (isLoggedOut ? '' : window.localStorage.getItem(StorageKeys.ACCESS_TOKEN) || '')

const handleLogoutNotify = (isLoggedOut, t) => {
  if (isLoggedOut) {
    Utils.showToastrMessage('info', t('devise.sessions.signed_out'))
    setAccessToken('')
    appsFlyerTrackEvent(SIGN_OUT)
    appsFlyerSetCUID('GUEST')
    window.history.replaceState({}, document.title, '/')
  }
}

const App = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentCustomer = useSelector((state) => state.currentCustomer)
  const urlParams = new URLSearchParams(window.location.search)
  const isLoggedOut = urlParams.get('log_out_success')
  const authenticationToken = currentToken(isLoggedOut)
  const lastLoginEmployId = currentCustomer.last_login_employ_id || null

  const areaIdUrl = utilsCommon.getParamFromURL('area_id')
  if (areaIdUrl) window.localStorage.setItem(StorageKeys.AREA_ID, areaIdUrl)
  const areaIdLocal = window.localStorage.getItem(StorageKeys.AREA_ID)
  const extraInfos = useSelector((state) => state.extraInfos)
  const finalAreaId = Number(extraInfos?.id || areaIdUrl || areaIdLocal)

  const isShowHeaderAndChat = initApp.isShowHeaderAndChat()

  const isReady = (extraInfos?.id && (!authenticationToken || currentCustomer.id)) || !isShowHeaderAndChat

  useEffect(() => {
    // check to sync language to session
    initApp.syncLanguageToSession()

    // Check params to redirect to LCL
    initApp.checkParamLCL()

    initBasicModal()

    handleLogoutNotify(isLoggedOut, t)

    // init token and connect cross storage
    connectToken(authenticationToken)

    //Enable test mode for AppsFlyer 
    appsFlyerTurnOnDevMode();

    // todo: we need to rehandle when use router dom
    // only for new booking
    checkToShowLoadingForNewBooking()

    window.addEventListener('message', handleListenMessage)
    return () => {
      window.removeEventListener('message', handleListenMessage)
    }
  }, [])

  useEffect(() => {
    const checkEventApprovedCompany = async() => {
      const { data: checkEvent } = await CustomerAPI.checkEventApprovedCompany()
      if (checkEvent?.show_notification) {
        await accountAction.switchAccount(checkEvent?.value?.[0])(dispatch)
        dispatch(accountManageActions.updateModalAccountManage(COMPANY_APPROVED_MODAL))
        await CustomerAPI.cleanEventApprovedCompany()
      }
    }
    if (currentCustomer?.id) {
      checkEventApprovedCompany()
      firebaseInit.load()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer?.id])

  useEffect(() => {
    dispatch(customerActionCreators.getCurrentCustomer(authenticationToken, finalAreaId, lastLoginEmployId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken, finalAreaId, lastLoginEmployId])

  useEffect(() => {
    dispatch(customerActionCreators.getCustomerSettings(authenticationToken, finalAreaId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalAreaId, extraInfos.country_code, currentCustomer.id, !!lastLoginEmployId])

  useEffect(() => {
    dispatch(areaActionCreators.getAreaSetting(authenticationToken, currentCustomer, finalAreaId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalAreaId, currentCustomer.last_login_employ_id, currentCustomer.id])

  useEffect(() => {
    initApp.checkToRedirectToLCLTracking(dispatch, currentCustomer, authenticationToken)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer.id, currentCustomer.last_login_employ_id, currentCustomer.authentication_token])

  //default show loading, we need hide loading for each pages
  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        <Suspense fallback={<LoadingModal />}>
          <BrowserRouter>
            <SGlobal />
            {isReady && (
              <>
                {isShowHeaderAndChat && (
                  <>
                    <FreshChat />
                    <NavBarContainer />
                  </>
                )}
                <RouteList />
              </>
            )}
            <LoadingModal />
          </BrowserRouter>
        </Suspense>
      </div>
    </ThemeProvider>
  )
}

export default App
